import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '../components/layout/Layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SectionWrapper } from '../components/common/wrappers/Wrappers'
import { PageTitle, SectionText } from '../components/common/typography/Typography'
import GalleryComponent from '../components/common/gallery/GalleryComponent'

export const query = graphql`
  query ($slug: String!) {
    hobbiesJson(slug: { eq: $slug }) {
      slug
      title
      text
      heroImage {
        childImageSharp {
          gatsbyImageData(
            width: 1650
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            breakpoints: [768, 1380]
          )
        }
      }
      gallery {
        original {
          publicURL
        }
        thumbnail {
          publicURL
        }
        vertical
      }
    }
  }
`

const Hobbies = ({ data, intl }) => {
  const hobbiesData = data.hobbiesJson
  return (
    <Layout pageName={hobbiesData.slug}>
      <SectionWrapper>
        <PageTitle>{intl.formatMessage({ id: hobbiesData.title })}</PageTitle>
        <HeroImageWrapper>
          <GatsbyImage image={hobbiesData.heroImage.childImageSharp.gatsbyImageData} alt="logo" />
        </HeroImageWrapper>
        <SectionText>{intl.formatMessage({ id: hobbiesData.text })}</SectionText>
      </SectionWrapper>
      <SectionWrapper>
        <GalleryComponent data={hobbiesData.gallery} />
      </SectionWrapper>
    </Layout>
  )
}

export default injectIntl(Hobbies)

const HeroImageWrapper = styled.div`
  margin: 30px 0;
`
