import React from 'react'
import styled from 'styled-components'
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { breakpoint, color } from '../../../configs/utilities'

const GalleryComponent = (props) => {
  return (
    <Wrapper>
      <Gallery>
        <ItemsWrapper>
          {props.data.map((item, index) => {
            return (
              <ItemWrapper key={index}>
                <Item
                  original={item.original.publicURL}
                  thumbnail={item.thumbnail.publicURL}
                  width={'600'}
                  height={item.vertical ? '884' : '442'}
                >
                  {({ ref, open }) => (
                    <img ref={ref} onClick={open} src={item.original.publicURL} alt="galleryImage" />
                  )}
                </Item>
              </ItemWrapper>
            )
          })}
        </ItemsWrapper>
      </Gallery>
    </Wrapper>
  )
}

export default GalleryComponent

const Wrapper = styled.div``

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 12px;
  row-gap: 12px;

  @media screen and (max-width: ${breakpoint.xsm}) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 18px;
    row-gap: 18px;
  }

  /* smallItemStyles */
  img {
    object-fit: cover;
    cursor: pointer;
    width: 100%;
    height: 25vh;
  }
`

const ItemWrapper = styled.div`
display: flex;
 background: ${color.white};
  box-shadow: 0px 4px 8px rgba(217, 217, 217, 0.6);
  padding: 5px;
`